import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { getBaseURL } from "@api";
import { getUserInfo } from "@/lib/auth";
import { Message } from "@/types/message";
import { ProfileGetters } from "@store/modules/profile/types";
import { newsModule, profileModule, messageModule } from "@store/namespaces";
import {
  NewsActions,
  NewsGetters,
  NewsMutations,
} from "@store/modules/news/types";
import {
  MessageActions,
  MessageGetters,
  MessageMutations,
} from "@store/modules/message/types";

import AppSidebar from "../../components/AppSidebar/AppSidebar.vue";
import { RootActions } from "@store/types";

@Component({
  components: {
    AppSidebar,
    NewsNotifications: () =>
      import("@/components/NewsNotifications/NewsNotifications.vue"),
    NewsPopup: () => import("@/components/NewsPopup/NewsPopup.vue"),
  },
})
export default class DashboardLayoutMixin extends Vue {
  @Action("fetchDashboardSummary")
  private readonly fetchDashboardSummaryAction!: RootActions["fetchDashboardSummary"];

  @profileModule.Getter
  private readonly canViewFacebookCodeNotifications!: ProfileGetters["canViewFacebookCodeNotifications"];
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];

  @messageModule.Action("markReadMessagesInFeed")
  private readonly markReadMessagesInFeedAction!: MessageActions["markReadMessagesInFeed"];
  @messageModule.Action("fetchFeedMessages")
  private readonly fetchFeedMessagesAction!: MessageActions["fetchFeedMessages"];
  @newsModule.Action("markReadNewsInFeed")
  private readonly markReadNewsInFeedAction!: NewsActions["markReadNewsInFeed"];
  @newsModule.Mutation("setNewsPopup")
  private readonly setNewsPopupAction!: NewsMutations["setNewsPopup"];
  @newsModule.Getter("lastNotReadNews")
  private readonly lastNotReadNewsGetter!: NewsGetters["lastNotReadNews"];
  @messageModule.Getter("notReadMessages")
  private readonly notReadMessagesGetter!: MessageGetters["notReadMessages"];

  @messageModule.Mutation("addMessageToFeed")
  private readonly addMessageToFeedMutation!: MessageMutations["addMessageToFeed"];

  private showedNewsNotifications = false;

  private get isActiveNewsPopup() {
    return !this.showedNewsNotifications;
  }

  private toogleNewsNotifications(showed = !this.showedNewsNotifications) {
    this.showedNewsNotifications = showed;
  }

  private goToProfile() {
    if (this.$route.name === "profile") return;

    this.$router.push({ name: "profile" });
  }

  private markReadNewsInFeed() {
    if (this.canViewFacebookCodeNotifications) {
      this.markReadMessagesInFeedAction({
        ids: this.notReadMessagesGetter.map(({ id }) => id),
      });
    }

    if (this.lastNotReadNewsGetter?.id) {
      this.setNewsPopupAction(null);
      this.markReadNewsInFeedAction({ newsId: this.lastNotReadNewsGetter.id });
    }
  }

  private onClickNewsPopup() {
    this.toogleNewsNotifications(true);
  }

  private onOpenedNewsNotifications() {
    this.markReadNewsInFeed();
  }

  private onVisibleNewsInViewport() {
    this.markReadNewsInFeed();
  }

  protected initMessagesSSE() {
    let eventSource: EventSource | null = null;

    this.$watch(
      () => {
        return this.canViewFacebookCodeNotifications;
      },
      (canViewFacebookCodeNotifications) => {
        if (!canViewFacebookCodeNotifications) {
          return;
        }

        eventSource?.close();
        eventSource = new EventSource(
          `${getBaseURL()}feed/messages/sse?x-token=${getUserInfo().token}`
        );

        eventSource.onmessage = ({ data }) => {
          if (this.$route.query.debug_sse_messages === "1") {
            console.log("sse message:", data);
          }

          if (!data) {
            return;
          }

          const message = JSON.parse(data) as Message;

          this.addMessageToFeedMutation(message);
        };

        this.$once("hook:beforeDestroy", () => {
          eventSource?.close();
          eventSource = null;
        });
      },
      {
        immediate: true,
      }
    );
  }

  protected initFeedNewsWatcher(): void {
    const intervalId = window.setInterval(() => {
      this.fetchDashboardSummaryAction();
    }, 90e3);

    this.$watch(
      () => {
        return this.profileLanguageGetter(this.$vuetify.lang.current);
      },
      () => {
        this.fetchDashboardSummaryAction();
        this.fetchFeedMessagesAction();
      },
      {
        immediate: true,
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearInterval(intervalId);
    });
  }
}
