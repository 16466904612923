import { Component, Vue, VModel } from "vue-property-decorator";
import { State } from "vuex-class";
import { Location } from "vue-router";
import { cloneStructured } from "@helpers";
import { telegramSupportBotLink, telegramSupportLink } from "@config/social";
import { Role } from "@/types/role";
import { ProfileGetters } from "@store/modules/profile/types";
import { profileModule, userModule } from "@store/namespaces";
import { RootState } from "@store/types";
import { UserGetters } from "@store/modules/user/types";
import { hasRole } from "@/lib/role";
import { HtmlElementId } from "@/types/element";
import { enabledStatisticByStatus } from "@/config/statistic";

interface NavItem {
  title: string;
  icon?: string;
  to?: Location;
  href?: string;
  target?: string;
  access?: Role[];
  items?: NavItem[];
  disabled?: boolean;
  id?: string;
  exact: boolean;
}

@Component({
  components: {
    AppSideBanner: () => import("@/components/AppSideBanner/AppSideBanner.vue"),
  },
})
export default class AppSidebar extends Vue {
  @VModel({ type: Boolean, default: true }) private showed!: boolean;
  @State
  private readonly enabledNewYearDesign!: RootState["enabledNewYearDesign"];
  @State
  private readonly enabledHalloweenDesign!: RootState["enabledHalloweenDesign"];

  @profileModule.Getter("profileAuthority")
  private readonly profileAuthorityGetter!: ProfileGetters["profileAuthority"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("profileTeamleadEmail")
  private readonly profileTeamleadEmailGetter!: ProfileGetters["profileTeamleadEmail"];
  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];
  @profileModule.Getter("accessToLimitCardsPage")
  private readonly accessToLimitCardsPageGetter!: ProfileGetters["accessToLimitCardsPage"];

  private miniVariant = false;
  private playingVideo = false;

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get expandOnHover() {
    if (this.isShowedMainIntro) {
      return false;
    }

    return (
      !this.$vuetify.breakpoint.mobile && this.$vuetify.breakpoint.width < 1570
    );
  }

  private get isStateless() {
    return this.expandOnHover || !this.$vuetify.breakpoint.mobile;
  }

  private get isMiniVariant() {
    return this.expandOnHover || this.miniVariant;
  }

  private get logoSrc() {
    if (this.enabledNewYearDesign) {
      return require("./skins/new-year-2024/img/logo-new-year.svg");
    }

    if (this.enabledHalloweenDesign) {
      if (this.miniVariant) {
        return require("@/assets/img/logo-blue.svg");
      }

      return require("./skins/halloween/img/logo.png");
    }

    return require("@/assets/img/logo-blue.svg");
  }

  private get canChangeLang() {
    return !this.userIsGhostGetter;
  }

  private get activeItemIndex() {
    const routeName = this.$route.name;

    return this.items.findIndex(
      (item) =>
        item.to?.name === routeName ||
        item.items?.some((subItem) => subItem.to?.name === routeName)
    );
  }

  private set activeItemIndex(val: number) {
    const itemLocation = this.items[val]?.to;

    if (
      itemLocation &&
      this.$route.name !== itemLocation.name &&
      !this.$vuetify.breakpoint.mobile
    ) {
      this.$router.push(itemLocation);
    }
  }

  private get width() {
    if (this.$vuetify.breakpoint.xlOnly) {
      return 300;
    }

    if (this.$vuetify.breakpoint.mobile) {
      return 240;
    }

    return 220;
  }

  private get miniVariantWidth() {
    if (this.$vuetify.breakpoint.xlOnly) {
      return 72;
    }

    return 58;
  }

  private get telegramSupportLink() {
    return telegramSupportLink;
  }

  private get appendItems() {
    return [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.support"),
        href: telegramSupportBotLink,
        target: "_blank",
        icon: "$telegramC",
        id: HtmlElementId.supportSidebarItem,
      },
      {
        text: "FAQ",
        to: {
          name: "faq",
        },
        icon: "$questionS",
        id: HtmlElementId.faqSidebarItem,
      },
    ];
  }

  private get items() {
    const userRole = this.profileAuthorityGetter;
    const checkAccess = (val?: Role[]) => !val || hasRole(userRole, val);

    const items: NavItem[] = [
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.profile"),
        id: HtmlElementId.profileSidebarItem,
        icon: "$leadership",
        exact: true,
        to: {
          name: "profile",
        },
        access: [
          Role.ROLE_OWNER,
          Role.ROLE_TEAMLEAD,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_NETWORK,
        ],
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.cards"),
        icon: "$creditCard",
        to: {
          name: "cards",
        },
        exact: false,
        access: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_NETWORK,
          Role.ROLE_ACCOUNTANT,
        ],
        items: [
          {
            title: this.$vuetify.lang.t(
              "$vuetify.dashboard.sidebar.card_with_balance"
            ),
            to: {
              name: "cards-with-balance",
            },
            id: HtmlElementId.balanceCardsSidebarItem,
            exact: false,
          },
          {
            title: this.$vuetify.lang.t(
              "$vuetify.dashboard.sidebar.card_with_limit"
            ),
            to: {
              name: "cards-with-limit",
            },
            exact: false,
            disabled: !this.accessToLimitCardsPageGetter,
          },
        ],
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.my_balance"),
        icon: "$moneyStack",
        access: [Role.ROLE_OWNER, Role.ROLE_TEAMLEAD, Role.ROLE_MEDIABUYER],
        exact: true,
        items: [
          {
            title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.refill"),
            id: HtmlElementId.refillSidebarItem,
            to: { name: "refill" },
            access: [Role.ROLE_OWNER, Role.ROLE_TEAMLEAD],
            exact: true,
          },
          {
            title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.converter"),
            id: HtmlElementId.converterSidebarItem,
            to: { name: "currency-converter" },
            exact: true,
          },
        ],
        disabled: this.userIsGhostGetter,
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.reports"),
        icon: "$transactions",
        access: [
          Role.ROLE_OWNER,
          Role.ROLE_ACCOUNTANT,
          Role.ROLE_TEAMLEAD,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_NETWORK,
        ],
        exact: true,
        items: [
          {
            title: this.$vuetify.lang.t(
              "$vuetify.dashboard.sidebar.transactions"
            ),
            id: HtmlElementId.transactionsSidebarItem,
            to: { name: "transactions" },
            access: [
              Role.ROLE_OWNER,
              Role.ROLE_ACCOUNTANT,
              Role.ROLE_TEAMLEAD,
              Role.ROLE_MEDIABUYER,
            ],
            exact: true,
          },
          {
            title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.cash_flow"),
            id: HtmlElementId.cashFlowSidebarItem,
            to: {
              name: "cash-flow",
            },
            exact: true,
          },
        ],
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.team"),
        id: HtmlElementId.teamSidebarItem,
        icon: "$teamLine",
        to: {
          name: "team",
        },
        access: [Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT, Role.ROLE_TEAMLEAD],
        exact: true,
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.statistic"),
        id: HtmlElementId.statisticSidebarItem,
        icon: "$pieChart",
        access: [
          Role.ROLE_OWNER,
          Role.ROLE_TEAMLEAD,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_ACCOUNTANT,
        ],
        to: {
          name: "statistic",
        },
        exact: true,
        items: [
          {
            title: this.$vuetify.lang.t(
              "$vuetify.dashboard.sidebar.statistic_by_type"
            ),
            to: {
              name: "statistic-by-type",
            },
            exact: true,
          },
          {
            title: this.$vuetify.lang.t(
              "$vuetify.dashboard.sidebar.statistic_by_status"
            ),
            to: {
              name: "statistic-by-status",
            },
            disabled:
              enabledStatisticByStatus.length > 0 &&
              !enabledStatisticByStatus.includes(this.profileEmailGetter) &&
              !enabledStatisticByStatus.includes(
                this.profileTeamleadEmailGetter
              ),
            exact: true,
          },
        ],
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.partners"),
        icon: "$suitcase",
        to: {
          name: "partners",
        },
        exact: true,
      },
      {
        title: this.$vuetify.lang.t(
          "$vuetify.dashboard.sidebar.referral_program"
        ),
        icon: "$megaphoneLine",
        to: {
          name: "referral-program",
        },
        access: [Role.ROLE_TEAMLEAD],
        exact: true,
      },
    ];

    return items.reduce<NavItem[]>((acc, item) => {
      const newItem = cloneStructured(item);

      if (checkAccess(newItem.access) && !newItem.disabled) {
        if (newItem.items) {
          newItem.items = newItem.items.filter(
            (subItem) => checkAccess(subItem.access) && !subItem.disabled
          );

          newItem.to = newItem.items.find((subItem) => !!subItem.to)?.to;
        }

        acc.push(newItem);
      }

      return acc;
    }, []);
  }

  private get supportLink() {
    return telegramSupportBotLink;
  }

  private onInputListGroup(showed: boolean, itemIndex: number) {
    this.activeItemIndex = showed ? itemIndex : -1;
  }

  private toggleMiniVariant(active = !this.miniVariant) {
    this.miniVariant = active;
  }

  private toggleShow(showed = !this.showed) {
    this.showed = showed;
  }

  private onPlaying() {
    this.playingVideo = true;
  }

  private onUpdateMiniVariant(value: boolean) {
    if (!this.expandOnHover || this.$vuetify.breakpoint.mobile) return;

    this.miniVariant = value;
  }

  private onMouseLeave() {
    this.miniVariant = this.expandOnHover || this.miniVariant;
  }

  private mounted() {
    this.$watch(
      () => {
        return this.expandOnHover;
      },
      (expandOnHover) => {
        this.toggleMiniVariant(expandOnHover);
      },
      {
        immediate: true,
      }
    );
  }
}
